<div class="process--receipt">
  <img class="cross--icon" src="assets/img/cross.svg" [mat-dialog-close] />
  <div class="d-flex mt-3 mob-flex">
    <img #receiptImage class="receipt--image" [src]="data?.image?.imgBase64" />
    <div *ngIf="!showWaitScreen && draftData?.ocr_data_json" class="position-absolute">
      <div *ngIf="draftData.ocr_data_json.amounts.length > 0 && !isSalesReceipt" class="highlight-amount"
        [ngStyle]="getOverlayStyles(draftData.ocr_data_json.amounts[0], true)"></div>
      <div *ngIf="draftData.ocr_data_json.receipt_numbers.length > 0" class="highlight-receipt"
        [ngStyle]="getOverlayStyles(draftData.ocr_data_json.receipt_numbers[0], true)"></div>
      <div *ngIf="draftData.ocr_data_json.dates.length > 0" class="highlight-date"
        [ngStyle]="getOverlayStyles(draftData.ocr_data_json.dates[0], true)"></div>
      <div *ngIf="draftData?.ocr_data_json?.Heuristic_Bound && draftData.ocr_data_json.Heuristic_Bound.length > 0">
        <div *ngFor="let item of heuristicData" class="highlight-date"
          [ngStyle]="getOverlayStyles(item.geometry, false)"></div>
      </div>
      <div *ngIf="otherOcrData.length > 0">
        <div *ngFor="let item of otherOcrData" class="highlight-others"
          [ngStyle]="getOverlayStyles(item.geometry, false)"></div>
      </div>
    </div>
    <div *ngIf="loading" class="d-flex flex-column align-items-center justify-content-center loader--container">
      <img src="assets/img/amplify-loader.gif" alt="Loader image" />
      <div class="processing--txt">{{ processingText }}</div>
    </div>
    <div *ngIf="!loading" class="floating-form ml-16 d-flex flex-column justify-content-between">
      <div *ngIf="showReceiptDetails">
        <div class="floating-field col-12">
          <input type="text" class="floating-input receipt-number" [(ngModel)]="receiptNumber" placeholder=" " />
          <label>{{ 'common.receipt.receiptNumber' | translate }}</label>
        </div>
        <div class="floating-field col-12">
          <div class="floating-input h-20 d-flex justify-content-between receipt-date">
            <input matInput class="date-input" [matDatepicker]="dateOfReceiptPicker" [(ngModel)]="dateOfReceipt"
              [max]="maxDate" (ngModelChange)="dateOfReceiptValidationGuard()" />
            <mat-datepicker-toggle matIconSuffix [for]="dateOfReceiptPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateOfReceiptPicker [startAt]="maxDate"></mat-datepicker>
          </div>
          <label>
            {{ "common.receipt.dateOfReceipt" | translate }}
          </label>
        </div>
        <div class="floating-field col-12">
          <input type="text" class="floating-input receipt-date" [(ngModel)]="orderNum" placeholder=" " />
          <label>{{ "common.receipt.orderNum" | translate }}</label>
        </div>
        <div class="floating-field col-12">
          <input type="text" class="floating-input receipt-date" [(ngModel)]="cashier" placeholder=" " />
          <label>{{ "common.receipt.cashier" | translate }}</label>
        </div>
        <div class="floating-field col-12">
          <textarea class="floating-input receipt-description" rows="4" [(ngModel)]="description"></textarea>
          <label>{{ 'common.receipt.description' | translate }}</label>
        </div>
        <div class="floating-field col-12" *ngIf="!isSalesReceipt">
          <input type="text" class="floating-input receipt-amount" [(ngModel)]="amount" placeholder=" " />
          <label>{{ 'common.receipt.amount' | translate }}</label>
        </div>
        <div class="btn--container">
          <div class="btn back--btn" [mat-dialog-close]>{{ 'common.back' | translate }}</div>
          <div class="btn continue--btn" (click)="saveReceiptData()" [mat-dialog-close]="receiptData">{{
            'common.continue' | translate }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column h-100" *ngIf="!isUploadSuccessful">
        <div class="icon d-flex justify-content-center align-items-center"><span class="timeout--icon"></span></div>
        <div class="ocr--txt">{{ 'common.receipt.ocrTimedOut' | translate }}</div>
        <div class="btn--container">
          <div class="btn back--btn" [mat-dialog-close]>{{ 'common.cancel' | translate }}</div>
          <div class="btn continue--btn" (click)="saveDraft()">{{ 'common.receipt.tryAgain' | translate }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column h-100" *ngIf="showWaitScreen">
        <div class="icon d-flex justify-content-center align-items-center"><span class="wait--icon"></span></div>
        <div class="ocr--txt">{{ 'common.receipt.waitingForOcr' | translate }}</div>
        <div class="btn--container">
          <div class="btn back--btn" (click)="saveReceiptData()" [mat-dialog-close]="receiptData">{{
            'common.receipt.skip' | translate }}</div>
          <div class="btn continue--btn" (click)="getDraftDetails()">{{ 'common.receipt.wait' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>